import React from "react";
import closeBtn from "../../imgs/close-btn.svg";
import QrScanner from "react-qr-scanner";
import { QRCodeSVG } from "qrcode.react";
// import axios from "axios";

const QR = ({userId, setLaunchQr, setOpenQuiz, setQuizIndex, scannerOrQr}) => {
    const handleScan = async (data) => {
        if (data) {
            setQuizIndex(Number(data.text));
            setOpenQuiz(true);
            setLaunchQr(false);
        }
    };

    const handleClose = () => {
        if (!scannerOrQr) {
            setLaunchQr(false);
            window.location.reload();
        } else {
            setLaunchQr(false);
        }
    };
    return (
        <div id='qr'>
            <div id='qr-box'>
                <img className="close-btn" src={closeBtn} alt='Close Button' onClick={() => handleClose()}/>
                <div id='qr-container' className={!scannerOrQr ? 'qr-code-border' : ''}>
                    {scannerOrQr ? <QrScanner
                id='qr-scanner'
                delay={300}
                onScan={handleScan}
                onError={(err) => console.error(err)}
                constraints={{ video: { facingMode: "environment" } }}
                /> : <QRCodeSVG value={userId} level='M' id='qr-code'/>}
                
                </div>
                <p id='user-id'>User ID: {userId}</p>
            </div>
        </div>
    )
};
export default QR;