import React, { useRef, useState } from "react";
import logo from "../../imgs/logo.svg";
import axios from "axios";

function Registration({setAuthToken, getCookie, setIsAdmin}) {
  const [resgisterOrLogin, setRegisterOrLogin] = useState(true); // true for register, false for login
  const [nameErrorMsg, setNameErrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [companyErrorMsg, setCompanyErrorMsg] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");

  const setCookie = (token, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "expires=" + date.toUTCString();
    }
    document.cookie = `authToken=${token}; path=/; Secure; SameSite=Strict; ${expires}`;
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        "https://api.virtualtechfrontier.com/wp-json/api/ge-login",
        {
          login_id: email,
        }
      );
      console.log(response);
      if (response.data.length == 1) {
        const token = JSON.stringify(response.data[0]);
        setCookie(token, 1); // stores session token for 1 day in cookies
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Invalid email");
    }
  };

  const handleRegister = async () => {
    console.log(email, name, company);
    try {
      const response = await axios.post(
        "https://api.virtualtechfrontier.com/wp-json/api/ge-registration",
        {
          email: email,
          name: name,
          company_name: company
        }
      );
      console.log(response);
      if (response.data.code == 200) {
        console.log('registration successful');
        return true;
      } else {
        console.log('registration failed');
        return false;
      }
    } catch (error) {
      console.log("Invalid name or Email or Company Name", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNameErrorMsg("");
    setEmailErrorMsg("");
    setCompanyErrorMsg("");

    if (!resgisterOrLogin && email=="000000000") {
      setIsAdmin(true);
    }
    // check name is not empty
    if (resgisterOrLogin && name == "") {
      setNameErrorMsg("Please enter your name.");
    }
    // check company name is not empty
    if (resgisterOrLogin && company == "") {
      setCompanyErrorMsg("Please enter your company name.");
    }
     //check email
    if (email == "") {
      if (!resgisterOrLogin) {
        setEmailErrorMsg("Email is required to login.");
      } else {
        setEmailErrorMsg("Please enter your email.");
      }
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      setEmailErrorMsg("Please enter a valid email.");
    } 
    // if there are any errors 
    if (nameErrorMsg != '' || emailErrorMsg != '' || companyErrorMsg != '') {
      return;
    }
    // if all user input is valid
    if (resgisterOrLogin) {
      // Register User
        console.log("Registering user...");
        if (await handleRegister()) {
          if (await handleLogin()) {
            setAuthToken(getCookie("authToken"));
          }
        } else {
          setEmailErrorMsg("Email already registered. Please login.");
        }
        
    } else {
      // Login user
      console.log("Logging in user...");
      if (await handleLogin()) {
        setAuthToken(getCookie("authToken"));
      } else {
        setEmailErrorMsg("Email not recognized. Please check your entry or register.");
      }
    }
  };

  const switchLoginRegister = () => {
    setEmailErrorMsg("");
    setNameErrorMsg("");
    setCompanyErrorMsg("");
    setEmail("");
    setName("");
    setCompany("");
    setRegisterOrLogin(!resgisterOrLogin);
  };
  return (
    <div className="registration">
      <img src={logo} alt="logo"/>
      <h2>{resgisterOrLogin ? "Registration" : "Login"}</h2>
      <div id="form" >
        {resgisterOrLogin && (<>
          <form id="name" className="form-group" onSubmit={(e) => handleSubmit(e)}>
            <p className="input-title">Name</p>
            <input
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
            ></input>
            <p className="form-error">{nameErrorMsg}</p>

          </form><form id="company" className="form-group" onSubmit={(e) => handleSubmit(e)}>
          <p className="input-title">Company Name</p>
          <input type="text" onChange={(e) => setCompany(e.target.value)} value={company}></input>
          <p className="form-error">{companyErrorMsg}</p>
        </form>
        </>
        )}
        <form id="email" className="form-group" onSubmit={(e) => handleSubmit(e)}>
          <p className="input-title">Email Address</p>
          <input type="email" onChange={(e) => setEmail(e.target.value)} value={email}></input>
          <p className="form-error">{emailErrorMsg}</p>
        </form>
        
      </div>
      <button onClick={handleSubmit}>
        {resgisterOrLogin ? "Register & Enter" : "Login"}
      </button>
      <div id="login-registration-switch">
       <div > {resgisterOrLogin ? "Already" : "Don't"} have an account?</div>
        <div id="loginRegister" onClick={() => switchLoginRegister()}>
          {resgisterOrLogin ? "Login" : "Register"}
        </div>
      </div>
    </div>
  );
}

export default Registration;
