import React, { useEffect, useState } from "react";
import backArrow from "../../imgs/back-arrow.svg"
import axios from "axios";


const Quiz = ({setOpenQuiz, quizIndex, question, answers, correctAnswer, stampStatus, setStampStatus, setWinPage, userId, checkStamps}) => {
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isWrong, setIsWrong] = useState(false);

    const saveStamp = async () => {
        try {
            const response = await axios.post(
              "https://api.virtualtechfrontier.com/wp-json/api/ge-stamping",
              {
                user_id: userId,
                booth: 'stamp_' + (quizIndex + 1)
              }
            );
            if (response.data.code == 200) {
              return true;
            } else {
                return false;
            }
          } catch (error) {
            console.log("Error stamping", error);
          }
    };
    const handleSubmit = async () => {
        if (selectedAnswer == correctAnswer) {
            console.log("Correct!");
            if (await saveStamp()) {
                let newStampStatus = [...stampStatus];
                newStampStatus[quizIndex] = true;
                console.log("Stamp status:", newStampStatus);
                if (newStampStatus.every(elem => elem == true)) {
                    setWinPage(true);
                }
                await checkStamps();
            }
            setOpenQuiz(false);
        } else {
            console.log("Incorrect!");
            setIsWrong(true);
        }
    };

    if (!question) {
        handleSubmit();
        return <></>;
    } 

    const handleAnswerClick = (index) => {
        setSelectedAnswer(index);
        setIsWrong(false);
    };
    const setAnswersClass = (index) => {
        if (selectedAnswer == index && isWrong) {
            return "answer-box wrong-answer";
        } else if (selectedAnswer == index) {
            return "answer-box selected-answer";
        } else {
            return "answer-box";
        }
    };
    return (
    <div id='quiz'>
        <div id='back-button-container'>
            <button id='back-button' onClick={() => setOpenQuiz(false)}><img src={backArrow} alt="Back Arrow"/> Back</button>
        </div>
        <h3 id='question-title'>Question {quizIndex + 1}</h3>
        <p id='question'>{question}</p>
        <div id='answers'>
            <div className="answer-row">
                <div className={setAnswersClass(0)} onClick={()=> handleAnswerClick(0)}>{answers[0]}</div>
                <div className={setAnswersClass(1)} onClick={()=> handleAnswerClick(1)}>{answers[1]}</div>
            </div>
            <div className="answer-row">
                <div className={setAnswersClass(2)} onClick={()=> handleAnswerClick(2)}>{answers[2]}</div>
                <div className={setAnswersClass(3)} onClick={()=> handleAnswerClick(3)}>{answers[3]}</div>
            </div>
        </div>
        <button id='submit-btn' onClick={() => handleSubmit()} disabled={selectedAnswer==null}>Submit</button>
        {isWrong ? <p id='wrong-msg'>Your answer is incorrect, try again!</p> : null}
    </div>);
};
export default Quiz;